/** @format */

const aqua = {
    50: "#E9F0FF",
    100: "#D2E0FF",
    200: "#A5C1FF",
    300: "#79A2FF",
    400: "#4C83FF",
    500: "#1F64FF",
    600: "#1950CC",
    700: "#133C99",
    800: "#0C2866",
    900: "#061433",
};

const maroon = {
    50: "#F2A1D1",
    100: "#E690C3",
    200: "#CE6CA6",
    300: "#B7498A",
    400: "#9F256D",
    500: "#870251",
    600: "#6C0241",
    700: "#510131",
    800: "#360120",
    900: "#1B0010",
};

const pink = {
    50: "#FFFAFC",
    100: "#FFF5F9",
    200: "#FFECF2",
    300: "#FEE2EC",
    400: "#FED9E5",
    500: "#FECFDF",
    600: "#ECA6BE",
    700: "#D97D9D",
    800: "#C7557B",
    900: "#B42C5A",
};

const yellow = {
    50: "#FFFCED",
    100: "#FEF8DB",
    200: "#FDF1B7",
    300: "#FCEA93",
    400: "#FBE36F",
    500: "#FADC4B",
    600: "#CEB53C",
    700: "#A18E2D",
    800: "#75661F",
    900: "#483F10",
};

const green = {
    50: "#ECF9F2",
    100: "#D8F3E4",
    200: "#B2E7C9",
    300: "#8BDBAE",
    400: "#65CF93",
    500: "#3EC378",
    600: "#329C60",
    700: "#257548",
    800: "#194E30",
    900: "#0C2718",
};

const pine = {
    50: "#AEE4DA",
    100: "#9ED7CD",
    200: "#7CBEB2",
    300: "#5BA496",
    400: "#398B7B",
    500: "#187160",
    600: "#135A4D",
    700: "#0E443A",
    800: "#0A2D26",
    900: "#051713",
};

const gold = {
    50: "#EDE8DD",
    100: "#E6DFCF",
    200: "#D8CCB4",
    300: "#CBBA99",
    400: "#BDA77E",
    500: "#AF9563",
    600: "#957F54",
    700: "#7B6945",
    800: "#625336",
    900: "#483D27",
};
const greyWarm = {
    0: "#FFFFFF",
    50: "#FDFDFD",
    100: "#FCFBFB",
    150: "#FAF8F8",
    200: "#F9F6F6",
    300: "#F5F2F2",
    400: "#F2EDED",
    500: "#EFE9E9",
    600: "#D4C9C9",
    700: "#B8A9A9",
    800: "#9D8A8A",
    850: "#8F7A7A",
    900: "#816A6A",
    950: "#745A5A",
    1000: "#664A4A",
};
const red = {
    50: "#FFEDED",
    100: "#FFDBDB",
    200: "#FFB8B7",
    300: "#FF9492",
    400: "#FF716E",
    500: "#FF4D4A",
    600: "#D33E3B",
    700: "#A62F2C",
    800: "#7A1F1E",
    900: "#4D100F",
};

const grey = {
    50: "#F7FAFC",
    100: "#EDF2F7",
    200: "#E2E8F0",
    300: "#CBD5E0",
    400: "#A0AEC0",
    500: "#718096",
    600: "#4A5568",
    700: "#2D3748",
    800: "#1A202C",
    900: "#171923",
};

export const colors = {
    aqua,
    maroon,
    pink,
    yellow,
    green,
    pine,
    gold,
    greyWarm,
    red,
    grey,
    brand: {
        50: "#b794f4",
        100: "#be9ef5",
        200: "#c5a9f6",
        300: "#ccb4f7",
        400: "#d3bef8", // default
        500: "#dbc9f9", // hover
        600: "#e2d4fa", // active
        700: "#e9defb",
        800: "#f0e9fc",
        900: "#f7f4fd",
    },
    black: "#000",
    blackAlpha: {
        blackAlpha: "rgba(26,26,26)",
        blackAlpha_50: "rgba(26, 26, 26, 0.04)",
        blackAlpha_100: "rgba(26, 26, 26, 0.06)",
        blackAlpha_200: "rgba(26, 26, 26, 0.08)",
        blackAlpha_300: "rgba(26, 26, 26, 0.16)",
        blackAlpha_400: "rgba(26, 26, 26, 0.24)",
        blackAlpha_500: "rgba(26, 26, 26, 0.36)",
        blackAlpha_600: "rgba(26, 26, 26, 0.48)",
        blackAlpha_700: "rgba(26, 26, 26, 0.64)",
        blackAlpha_800: "rgba(26, 26, 26, 0.8)",
        blackAlpha_900: "rgba(26, 26, 26, 0.92)",
    },
    white: {
        whiteAlpha: "#FFFFFF",
        whiteAlpha_50: "#FFFFFF0A",
        whiteAlpha_100: "#FFFFFF0F",
        whiteAlpha_200: "#FFFFFF14",
        whiteAlpha_300: "#FFFFFF29",
        whiteAlpha_400: "#FFFFFF3D",
        whiteAlpha_500: "#FFFFFF5C",
        whiteAlpha_600: "#FFFFFF7A",
        whiteAlpha_700: "#FFFFFFA3",
        whiteAlpha_800: "#FFFFFFCC",
        whiteAlpha_900: "#FFFFFFEB",
    },
    custom1: {
        100: "#0caa60",
        // 200: "#0A9956",
        // 300: "#09884C",
        // 400: "#087743",
        // 500: "#076639",
        // 600: "#065530",
        // 700: "#044426",
        // 800: "#03331C",
        // 900: "#022213",
    },
};
